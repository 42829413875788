<template>
  <div
    class="info-view-container"
  >
    <tournament-view-header>
      <template
        #view-header
      >
        <h5 class="m-0">
          Tournament info & rules
        </h5>
      </template>
    </tournament-view-header>

    <b-row>
      <b-col>
        <b-card
          v-if="tournament?.status_id === TournamentStatusType.FINISHED|| tournament?.status_id === TournamentStatusType.CLOSED"
          style="background-color: #28304547"
        >
          <h5 class="m-0 mb-2">
            Final results
          </h5>

          <b-row>
            <b-col
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/1stplace.png`)"
                    alt="1st place"
                    height="35"
                  />
                </span>
                <span
                  class="font-weight-bold ml-2 font-medium-2"
                >{{
                    tournament?.rankings[0].participant?.participant?.name || tournament?.rankings[0].participant?.name
                }}</span>
              </b-card>
            </b-col>
            <b-col
              v-if="tournament?.rankings.length >= 2"
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/2place.png`)"
                    alt="2nd place"
                    height="35"
                  />
                </span>
                <span class="font-weight-bold ml-2 font-medium-2">
                  {{
                    tournament?.rankings[1].participant?.participant?.name || tournament?.rankings[1].participant?.name
                  }}
                </span>
              </b-card>
            </b-col>

            <b-col
              v-if="tournament?.rankings.length >= 3"
              sm="12"
              md="4"
            >
              <b-card>
                <span>
                  <b-img
                    :src="require(`@/assets/images/icons/3place.png`)"
                    alt="3rd place"
                    height="35"
                  />
                </span>
                <span class="font-weight-bold ml-2 font-medium-2">
                  {{
                    tournament?.rankings[2].participant?.participant?.name || tournament?.rankings[2].participant?.name
                  }}
                </span>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-if="isLoading"
      class="text-center p-2"
    >
      <b-spinner label="Loading..." />
    </b-row>

    <b-row
      v-else
      :class="tournament?.status_id >= TournamentStatusType.FINISHED ? 'scroll-container final-results' : 'scroll-container'"
    >
      <b-col class="h-100">
        <b-tabs
          content-class="mt-1 h-100"
          pills
          class="h-100"
        >
          <!-- Info -->
          <b-tab
            title="Information"
            style="height: calc(100% - 40px);"
          >
            <b-card
              class="h-100"
              body-class="h-100"
            >
              <b-row class="h-100">
                <b-col class="h-100">
                  <vue-perfect-scrollbar
                    :settings="{suppressScrollX: true,}"
                    class="h-100"
                  >
                    <p v-if="!tournament?.info">
                      No information provided by the organizer.
                    </p>
                    <p
                      v-else
                      v-html="tournament?.info"
                    />
                  </vue-perfect-scrollbar>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>

          <!-- Rules -->
          <b-tab
            lazy
            title="Rules"
            style="height: calc(100% - 50px);"
          >
            <b-card
              class="h-100 x"
            >
              <b-row class="row h-100">
                <b-col class="h-100">
                  <vue-perfect-scrollbar
                    :settings="{suppressScrollX: true,}"
                    class="h-100"
                  >
                    <p v-if="!tournament?.rules">
                      No rules provided by the organizer.
                    </p>
                    <p
                      v-else
                      v-html="tournament?.rules"
                    />
                  </vue-perfect-scrollbar>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {
  BCard, BCol, BImg, BRow, BSpinner, BTab, BTabs,
} from 'bootstrap-vue'
import { breadcrumbManager } from '@core/mixins/bredcrumb-manager'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TournamentStatusType from '@/constants/TournamentStatusType'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import EventBus from '@/common/event-bus'
import TournamentViewHeader from '@/views/tournament/components/TournamentViewHeader.vue'

export default {
  components: {
    TournamentViewHeader,
    BImg,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    VuePerfectScrollbar,
  },
  mixins: [breadcrumbManager],
  data() {
    return {
      info: null,
      rules: null,
      tournament: null,
      mqShallShowLeftSidebar: useResponsiveAppLeftSidebarVisibility(),
    }
  },
  computed: {
    EventBus() {
      return EventBus
    },
    TournamentStatusType() {
      return TournamentStatusType
    },
    isLoading() {
      return this.$store.getters['tournament/isLoading']
    },
    title() {
      return this.$store.getters['tournament/getTournament']?.name
    },
    productTitle() {
      return this.$store.getters['tournament/getTournament']?.name
    },
  },
  async created() {
    await this.$store.dispatch('tournament/fetchTournament', this.$route.params.id)

    this.tournament = await this.$store.getters['tournament/getTournament']
    if (!this.tournament) {
      await this.$router.push({ name: 'error-404' })
    }

    await this.hydrateBreadcrumbs()
  },

  methods: {
    async hydrateBreadcrumbs() {
      const data = await this.$store.getters['tournament/getTournament']
      if (data != null) {
        this.replaceBreadcrumb({
          find: 'tournamentName',
          replace: {
            text: data?.name,
            loading: false,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.info-view-container {
  height: inherit;

  .card {
    margin-bottom: 1rem;
  }
}

.scroll-container.final-results {
  height: calc(100% - 310px)
}

.scroll-container {
  // height: calc(100% - 95px);
}

@media (max-width: 767px) {
  .info-view-container {
    height: inherit;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .scroll-container.final-results {
    height: 100%
  }
}

</style>
